
/* colors */

$dark-01: #101828;
$dark-02: #475467;

$white-01: #FFF;

$gray-01: #D0D5DD;
$gray-02: #344054;
$gray-03: #EAECF0;
$gray-04: #F9FAFB;
$gray-05: #1D2939;
$gray-06: #F2F4F7;
$gray-07: #FCFCFD;
$gray-08: #667085;


$action-01: #7F56D9;
$action-02: #7E7493;
$action-03: #6941C6;
$action-04: #F9F5FF;
$action-05: #039855;
$action-06: #D6BBFB;
$action-07: #E9D7FE;
$action-08: #F4EBFF;

$error-01: #CF263F;
$error-02: #FECDCA;
$error-03: #DC6803;


/* media queries */
$tablet: 'min-width: 600px';
$desktop: 'min-width: 801px';

/* shadows */
$shadow-1: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
$shadow-2: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
