@import './variables.scss';

/* --- elements */

@mixin container {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  padding: 121px 5px;
  display: flex;
  flex-direction: column;
}

@mixin input { 
  height: 44px;
  border-radius: 8px;
  border: 1px solid $gray-01;
  margin-bottom: 20px;
  padding-left: 14px;
  &:disabled {
    background-color: $white-01;
    cursor: not-allowed !important
  }
}

@mixin textarea {
  @include md-regular;
  font-family: 'Inter', sans-serif;
  padding: 12px 14px;
  color: $dark-01;
}

@mixin button {
  @include md-semibold;
  height: 44px;
  background-color: $action-01;
  border-radius: 8px;
  color: $white-01;
  border: 1px solid $action-01;
  padding: 10px 18px;
  cursor: pointer;
  pointer-events: auto;
  &:hover { background-color: $action-03; }
  &:disabled {
    background-color: $action-02;
    cursor: not-allowed !important
  }
}

@mixin button-neutral {
  @include button;
    background-color: $white-01;
    color: $dark-01;
    border-color: $dark-01;
    display: flex;
    &:hover { background-color: $action-04; }
    &:disabled { background-color: $gray-06; }
}

@mixin button-action {
  @include button;
  @include sm-semibold;
  display: flex;
  align-items: center;
  background-color: $action-05;
  border-color: $action-05;
  padding: 8px 14px;
  height: 36px;
  margin: 0 auto;
  &:hover { background-color: darken($color: $action-05, $amount: 5) }
}

@mixin button-inactive {
  @include sm-semibold;
  height: 44px;  
  border-radius: 8px;
  background-color: $white-01;
  cursor: not-allowed;
  border: 1px solid $gray-01;
  color: $gray-01
}

@mixin label($text-color, $background-color) {
  @include xs-medium;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 16px;
  background-color: $background-color;
  color: $text-color;
}

@mixin checkbox {
  height: 16px;
  width: 16px;
  appearance: none;
  border: 1px solid $gray-01;
  border-radius: 4px;
  margin-right: 8px;
  &:checked {
    appearance: auto;
    accent-color: $action-01;
  }
}

/* --- typography */

@mixin header {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}

@mixin display-md-base {
  font-style: normal;
  font-size: 36px;
  line-height: 44px;
}

@mixin display-md-semibold {
  @include display-md-base;
  font-weight: 600;
}

@mixin lg-base {
  font-style: normal;
  font-size: 48px;
  line-height: 60px;
}

@mixin lg-medium {
  @include lg-base;
  font-weight: 500;
}

@mixin xl-text-base {
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
}

@mixin xl-text-regular {
  @include xl-text-base;
  font-weight: 400;
}

@mixin xl-text-semibold {
  @include xl-text-base;
  font-weight: 600;
}

@mixin lg-text-base {
  font-style: normal;
  font-size: 18px;
  line-height: 28px;  
}

@mixin lg-text-semibold {
  @include lg-text-base;
  font-weight: 600;
}

@mixin lg-text-medium {
  @include lg-text-base;
  font-weight: 500;
}

@mixin lg-text-regular {
  @include lg-text-base;
  font-weight: 400;
}

@mixin md-base {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

@mixin md-regular {
  @include md-base;
  font-weight: 400;
}

@mixin md-semibold {
  @include md-base;
  font-weight: 600;
}

@mixin sm-base {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

@mixin sm-bold {
  @include sm-base;
  font-weight: 700; 
}

@mixin sm-semibold {
  @include sm-base;
  font-weight: 600;
}
@mixin sm-medium {
  @include sm-base;
  font-weight: 500;
}

@mixin sm-regular {
  @include sm-base;
  font-weight: 400;
}

@mixin xs-base {
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

@mixin xs-medium {
  @include xs-base;
  font-weight: 500;
}

@mixin xs-regular {
  @include xs-base;
  font-weight: 400;
}

@mixin display-xs-base {
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
}
@mixin display-xs-semibold {
  font-weight: 600;
}


/* --- media queries */
@mixin tablet {
  @media ($tablet) {
    @content
  }
}

@mixin desktop {
  @media ($desktop) {
    @content
  }
}
