@import './variables.scss';
@import './mixins.scss';

/* --- angular material override --- */

@mixin override-mat-tab {
    height: 44px!important;
    background-color: $gray-06;
    border: 1px solid $gray-06;
    cursor: pointer;
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    &:nth-last-child(3) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:nth-last-child(2) {
      margin-left: auto;
    }
    &:nth-last-child(2), &:last-child {
      background-color: transparent;
      padding-right: 0;
      border-color: transparent;
    }
    &:last-child {
      padding-left: 10px;
    }
    &.mdc-tab--active  {
    cursor: default;
      .button-label {
        @include sm-semibold;
        height: 36px;
        background-color: $white-01;
        border: 0;
        border-radius: 8px;
        cursor: default !important;
      }
    }
  
    /* tab :hover + :focus */ 
    .mdc-tab__ripple::before {
      background-color: $gray-06;
    }
    /* tab underline */
    .mdc-tab-indicator__content--underline {
      display: none;
    }

    /* disabled tabs working as action buttons */
    &.mat-mdc-tab-disabled {
      opacity: 1;
    }
  }

  @mixin override-mat-table {
    border: 1px solid $gray-03 !important;
    border-radius: 8px !important;
    .mdc-data-table__row:last-child .mdc-data-table__cell {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    thead, .mat-mdc-header-cell {
      @include xs-medium;
      color: $dark-02;
      &:first-child {
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        text-align: center;
      } 
    }
    .mat-mdc-cell {
      @include sm-medium;
      color: $dark-01;
      &:last-child {
        text-align: center;
      } 
      /* select in table table */
      .mat-mdc-form-field {
        min-height: 36px;
        max-height: 36px;
        .mat-mdc-text-field-wrapper {
          min-height: 36px;
          max-height: 36px;
          border: 1px solid $action-03;
        }
        .mat-mdc-select-placeholder, .mat-mdc-select-value-text {
          color: $action-03;
        }
        .mat-mdc-select-arrow {
          color: $action-03
        }
        .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
          padding-top: 6px;
          padding-bottom: 0px;
        }
        .mat-mdc-floating-label { padding-bottom: 12px; }
      }
    }
  }

  @mixin override-mat-table-patients {
    .mat-mdc-table {
      border-radius: 0px !important;
    }
  }

  @mixin override-mat-table-home { 
    .mat-mdc-table {
      border-radius: 0px !important;
      thead, .mat-mdc-header-cell {
        &:first-child {
          border-top-left-radius: 0px !important;
        }
      }
    }
   }