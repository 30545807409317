@font-face {
    font-family: 'Oswald';
    src: url('../assets/fonts/bold/Oswald-Bold.woff2') format('woff2'),
        url('../assets/fonts/bold/Oswald-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/fonts/regular/Oswald-Regular.woff2') format('woff2'),
        url('../assets/fonts/regular/Oswald-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/fonts/semibold/Oswald-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/semibold/Oswald-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/fonts/light/Oswald-Light.woff2') format('woff2'),
        url('../assets/fonts/light/Oswald-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/fonts/medium/Oswald-Medium.woff2') format('woff2'),
        url('../assets/fonts/medium/Oswald-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/fonts/extralight/Oswald-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/extralight/Oswald-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
